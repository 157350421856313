<template>
  <div style="text-align:center">
    <el-date-picker v-model="month" type="month" @change="onload" placeholder="选择月" value-format="yyyy-MM" :clearable="false"></el-date-picker>
    <div style="margin:20px 0">{{month}}总活跃人数：{{total || 0}}</div>
    <div id="line-chart" class="chart-box"></div>
  </div>
</template>

<script>
import moment from 'moment'
import { getUserOnLine } from '@/api/customer.js'
import * as echarts from 'echarts';
var myChart2 = null
export default {
  name: 'customerActive',
  data() {
    return {
      month: moment().format('YYYY-MM'),
      total: 0
    }
  },
  mounted() {
    this.onload()
  },
  methods: {
    onload() {
      getUserOnLine({ date: this.month }).then(res => {
        console.log(res)
        let result = res.data || {}
        this.total = result.total
        let list = result.list.map(item => {
          let key = Object.keys(item)[0]
          let value = item[key]
          return value
        })
        let list2 = result.list.map(item => {
          let key = Object.keys(item)[0]
          return key
        })
        this.lineChart(list2, list)
      })
    },
    lineChart(monthList, arr) {
      myChart2 = echarts.init(document.getElementById('line-chart'));
      // 绘制图表
      myChart2.setOption({
        title: {
          text: '活跃人数统计图表',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: monthList
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'value'
        },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '3%',
          top: '22%',
          containLabel: true
        },
        // legend: {
        //   orient: 'vertical',
        //   left: 'center',
        // },
        series: [
          {
            name: '活跃人数',
            type: 'line',
            stack: '总量',
            data: arr
          }
        ]
      });
    }
  }
}
</script>

<style scoped>
.chart-box {
  width: 90vw;
  height: 70vh;
}
</style>